<div *ngIf="!showSpinner">
  <div class="header">
    <img
      class="ey-logo"
      src="../../../assets/images/libraryNavigationLogo.png"
    />
  </div>
  <div class="center" *ngIf="!selectDashboard">
    <button class="btn" (click)="login('internal')">EY User</button>
    <button class="btn" (click)="login('external')">Client User</button>
  </div>
  <div class="center" *ngIf="selectDashboard">
    <label>Select the dashboard</label>
    <select [formControl]="dashboardControl">
      <option value="combined">Combined Dashboard</option>
      <option value="domestic">Domestic PE Rules</option>
      <option value="pe">PE Risk Analysis</option>
    </select>
    <button class="btn" (click)="goToDashboard()">Go</button>
  </div>
</div>
<div class="spin" *ngIf="showSpinner"></div>
