import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import {
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private msalService: MsalService) {}

  public isLoggedIn: boolean = false;
  public user: User | undefined;
  public apiUrl = environment.apiUrl;
  public userType : string = "";

  setTenant(userType: string) {
    this.userType = userType;
    authority =
      environment.authority + userType == 'external'
        ? environment.eyExtTenant
        : environment.eyTenant;
      // this.msalService.instance = new PublicClientApplication({
      //   auth: {
      //     clientId: environment.clientId,
      //     authority: authority,
      //     redirectUri: environment.redirectUrl,
      //     postLogoutRedirectUri: environment.redirectUrl,
      //     navigateToLoginRequestUrl: true,
      //   },
      //   cache: {
      //     cacheLocation: 'localStorage',
      //     storeAuthStateInCookie: isIE,
      //   },
      // });
    // MSALInstanceFactory();
  }

  verifyUserAccess() {
    return this.http.post(this.apiUrl+'/verifyUser',{email: this.user?.email, userType: this.userType});
  }
}

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;
// var authority = environment.authority + environment.eyTenant;
var authority = environment.authority + 'organizations';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: authority,
      redirectUri: environment.redirectUrl,
      postLogoutRedirectUri: environment.redirectUrl,
      navigateToLoginRequestUrl: true,

    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE,
    },
  });
}
