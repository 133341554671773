import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmbedConfigToken } from '../models/embed-config.model';

@Injectable({
  providedIn: 'root'
})
export class PowerbiService {
  apiURL = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getEmbedReportConfig(reqObj:any): Observable<EmbedConfigToken>{
    let uri = this.apiURL + '/getEmbedToken';
    return this.http.post<EmbedConfigToken>(uri,reqObj);
  }
}
