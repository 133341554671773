// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: window.location.origin,
  clientId: '773841d8-d6fd-46f5-aa07-deca059b8cef',
  authority: 'https://login.microsoftonline.com/',
  eyTenant: '5b973f99-77df-4beb-b27d-aa0c70b8482c',
  eyExtTenant: '99050bca-65fe-4f6d-8478-388cd894a57f',
  redirectUrl: window.location.origin + '/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
