import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IReportEmbedConfiguration, models } from 'powerbi-client';
import { Subscription } from 'rxjs';
import { EmbedConfigToken } from 'src/app/models/embed-config.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { PowerbiService } from 'src/app/services/powerbi.service';

@Component({
  selector: 'app-combined-dashboard',
  templateUrl: './combined-dashboard.component.html',
  styleUrls: ['./combined-dashboard.component.css'],
})
export class CombinedDashboardComponent implements OnInit {
  reportType: string | null | undefined = 'combined';

  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      background: models.BackgroundType.Default,
      zoomLevel: 1,
    },
  };
  getEmbedReportConfigSub: Subscription | undefined;
  private user:User | undefined;
  constructor(
    private powerbiService: PowerbiService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.reportType = this.activatedRoute.snapshot.paramMap.get('reportType')?.split('?')[0];
    this.user = this.authService.user;
    this.getEmbedReportConfig();
  }
  getEmbedReportConfig() {
    const reqObj = {
      reportType: this.reportType,
      email: this.user?.email,
      role: this.user?.role
    }
    this.getEmbedReportConfigSub = this.powerbiService
      .getEmbedReportConfig(reqObj)
      .subscribe({
       next: (config: EmbedConfigToken) => {
        if (config && config.status == 200) {
          this.reportConfig = {
            ...this.reportConfig,
            accessToken: config.accessToken,
            embedUrl: config.embedUrl[0].embedUrl,
          };
        }
        else{
          console.log(config)
          alert('Some issue occured. Please try again later.');
        }
      },
      error: (err) => {
        console.log(err);
        const errMsg = err.error.error;
        alert(errMsg);
        this.router.navigate(['/notFound']);
      }
    }
      );
  }
}
