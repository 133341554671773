import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private msalService: MsalService,
    private router: Router
  ) {}
  public selectDashboard: boolean = false;
  public dashboardControl: FormControl = new FormControl('');
  public showSpinner: boolean = false;
  ngOnInit(): void {
    const redirectURL = localStorage.getItem('redirectURL');
    if (redirectURL) {
      const queryParam = redirectURL.split('?')[1];
      if (queryParam) {
        const paramKey = queryParam.split('=')[0];
        const paramValue = queryParam.split('=')[1];
        if (paramKey && paramValue && paramKey == 'userType') {
          this.login(paramValue);
        }
      }
    }
  }
  login(userType: string) {
    this.showSpinner = true;
    const allowedAuthorities = [environment.eyExtTenant, environment.eyTenant];
    this.authService.setTenant(userType);
    this.msalService.loginPopup().subscribe({
      next: (result: any) => {
        let authority = result.account.tenantId;
        if (allowedAuthorities.indexOf(authority) == -1) {
          localStorage.setItem('isLoggedIn', 'false');
          this.authService.isLoggedIn = false;
          this.router.navigate(['/unauthorized']);
          return;
        }
        this.authService.user = {
          name: result.account?.name,
          email: result.account?.username,
        };
        if (
          this.authService.user &&
          this.authService.user.name != undefined &&
          this.authService.user.email != undefined
        ) {
          this.authService.verifyUserAccess().subscribe({
            next: (res: any) => {
              console.log(res);
              if (res[0] == undefined) {
                localStorage.removeItem('redirectURL');
                this.router.navigate(['/unauthorized']);
                return;
              }
              // if()
              if (userType == 'internal' && res[0]['isAdmin']) {
                this.authService.user = {
                  ...this.authService.user,
                  role: ['admin'],
                };
                this.authService.isLoggedIn = true;
                localStorage.setItem('isLoggedIn', 'true');
                localStorage.setItem(
                  'user',
                  JSON.stringify(this.authService.user)
                );
                const redirectURL = localStorage.getItem('redirectURL');
                if (redirectURL && redirectURL != '') {
                  localStorage.removeItem('redirectURL');
                  this.router.navigate([redirectURL]);
                } else {
                  // this.router.navigate(['/notFound']);
                  this.selectDashboard = true;
                }
              } else if (userType == 'internal' && !res[0]['isAdmin']) {
                this.authService.user = {
                  ...this.authService.user,
                  role: [userType],
                };
                this.authService.isLoggedIn = true;
                localStorage.setItem('isLoggedIn', 'true');
                localStorage.setItem(
                  'user',
                  JSON.stringify(this.authService.user)
                );
                const redirectURL = localStorage.getItem('redirectURL');
                if (redirectURL && redirectURL != '') {
                  localStorage.removeItem('redirectURL');
                  this.router.navigate([redirectURL]);
                } else {
                  // this.router.navigate(['/notFound']);
                  this.selectDashboard = true;
                }
              } else if (res[0]['assignedProjects'] > 0) {
                this.authService.user = {
                  ...this.authService.user,
                  role: [userType],
                  typeOfDashboard:
                    Object.keys(res).length > 2
                      ? 'combined'
                      : res[0].typeOfDashboard,
                };
                localStorage.setItem('isLoggedIn', 'true');
                localStorage.setItem(
                  'user',
                  JSON.stringify(this.authService.user)
                );
                this.authService.isLoggedIn = true;
                const redirectURL = localStorage.getItem('redirectURL');
                const redirect =
                  this.authService.user.typeOfDashboard == null
                    ? 'combined'
                    : this.authService.user.typeOfDashboard;
                if (redirect) {
                  this.router.navigate(['/powerbi/' + redirect]);
                } else if (redirectURL && redirectURL != '') {
                  localStorage.removeItem('redirectURL');
                  this.router.navigate([redirectURL]);
                } else {
                  this.router.navigate(['/notFound']);
                }
              } else {
                localStorage.setItem('isLoggedIn', 'false');
                this.authService.isLoggedIn = false;
                this.router.navigate(['/unauthorized']);
              }
              this.showSpinner = false;
            },
            error: (err) => {
              console.log(err);
              localStorage.setItem('isLoggedIn', 'false');
              this.authService.isLoggedIn = false;
              this.showSpinner = false;
              this.router.navigate(['/unauthorized']);
            },
          });
        }
      },
      error: (err) => {
        this.showSpinner = false;
        console.log(err);
      },
    });
  }

  goToDashboard() {
    const typeOfDashboard = this.dashboardControl.value;
    this.router.navigate(['/powerbi/' + typeOfDashboard]);
  }
}
