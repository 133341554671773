import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') || 'false');
      const user: User = JSON.parse(localStorage.getItem('user') || '{}');
      if(user && user.email && user.name){
        this.authService.user = {
          ...user
        }
        this.authService.isLoggedIn = isLoggedIn;
      }
      if(isLoggedIn)
        return true;
      localStorage.setItem('redirectURL',state.url)
      this.router.navigate(['/home'])
      return true;
  }
}
